import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import authSelectors from "../../redux/auth/authSelectors";

console.log("PrivateRoute before ");

export default function PrivateRoute({ children, ...routeProps }) {
  // console.log("PrivateRoute routeProps ==>> ", { ...routeProps });

  const IsLoggedIn = useSelector(authSelectors.getIsLoggedIn);

  // console.log("PrivateRoute ~ IsLoggedIn ==>>  ", IsLoggedIn);

  return (
    <Route {...routeProps}>
      {IsLoggedIn ? children : <Redirect to="/login" />}
    </Route>
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.element,
};
