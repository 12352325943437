import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import WidgetsIcon from "@material-ui/icons/Widgets";
import DetailsIcon from "@material-ui/icons/Details";
// import AddCommentIcon from "@material-ui/icons/AddComment";
import ModeCommentIcon from "@material-ui/icons/ModeComment";
// import { ReviewsIcon } from "@mui/icons-material/Reviews";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
// import Cloud from "@material-ui/icons/Cloud";
// react-bootstrap
// import Tabs from "react-bootstrap/Tabs";
// import Tab from "react-bootstrap/Tab";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";

import TemplateItemTab from "components/TemplateItemTab";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";

// import tableStyles from "assets/jss/material-dashboard-react/components/tableStyle.js";

// @material-ui/core components
import Loader from "components/Loader";

import mmApi from "../../services/mmApi";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  itemTitle: {
    height: "48px",
    color: "inherit",
    fontWeight: "500",
    outline: "none",
    verticalAlign: "middle",
    marginLeft: "20px",
  },
};

const useStyles = makeStyles(styles);

export default function CatalogItem() {
  const classes = useStyles();
  const { catalogid } = useParams();
  const [catalog, setCatalog] = useState(null);

  let dateUpdate = null;

  // console.log("CatalogItem ~ catalogid ==>> ", catalogid);

  useEffect(() => {
    console.log("CatalogItem useEffect ~ useEffect: ");
    fetchCatalogAbout();
  }, []);

  const fetchCatalogAbout = () => {
    mmApi
      .fetchCatalogAbout(catalogid)
      .then((data) => {
        console.log("DATA fetchCatalogAbout ~ data: ", data);
        setCatalog(data);
      })
      .catch((error) => {
        console.log("DATA fetchCatalogAbout ~ ERROR: ", error);
      })
      .finally(() => {
        console.log("DATA fetchCatalogAbout ~ FINALY: ");
      });
  };

  if (catalog) {
    const unixUpdate = new Date(catalog.update * 1000);
    const timezoneOffset = unixUpdate.getTimezoneOffset();
    const resDate = new Date((catalog.update - timezoneOffset) * 1000);
    dateUpdate = `${resDate.getDate()}.${resDate.getMonth()}.${resDate.getFullYear()} ${resDate.getHours()}:${resDate.getMinutes()}`;
  }

  console.log("CatalogItem ~ catalog ==>> ", catalog);
  console.log("CatalogItem ~ dateUpdate ==>> ", dateUpdate);

  return (
    <>
      {catalog ? (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={9}>
              <h3 className={classes.itemTitle}>{catalog.name}</h3>
              <CustomTabs
                // title="Cataloguwe: ${catalog.name}"
                headerColor="primary"
                tabs={[
                  {
                    tabName: "Datails",
                    tabIcon: DetailsIcon,
                    tabContent: (
                      <TemplateItemTab
                        name="Datails"
                        requestNameApi="datails"
                      />
                    ),
                  },
                  {
                    tabName: "Workouts",
                    tabIcon: WidgetsIcon,
                    tabContent: (
                      <TemplateItemTab
                        name="Workouts"
                        requestNameApi="workouts"
                      />
                    ),
                  },
                  {
                    tabName: "Reviews",
                    tabIcon: ModeCommentIcon,
                    tabContent: (
                      <TemplateItemTab
                        name="Reviews"
                        requestNameApi="reviews"
                      />
                    ),
                  },
                  {
                    tabName: "QA",
                    tabIcon: QuestionAnswerIcon,
                    tabContent: (
                      <TemplateItemTab name="QA" requestNameApi="qa" />
                    ),
                  },
                ]}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card profile>
                <CardAvatar profile>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      src={catalog.user.profile.avatar.base}
                      alt={catalog.user.autor}
                    />
                  </a>
                </CardAvatar>
                <CardBody profile>
                  <h4 className={classes.cardTitle}>
                    <b>{catalog.user.autor}</b>
                  </h4>
                  <h5>
                    <b>{catalog.subscribers_count} followers</b>
                  </h5>
                  <h5>
                    $ <b>{catalog.price}</b>
                  </h5>

                  <p className={classes.description}>
                    {catalog.user.description}
                  </p>
                  <p>
                    <b>Category:</b> {catalog.category.name}
                  </p>
                  <p>
                    <b>Period:</b> {catalog.count_days}
                  </p>
                  <p>
                    <b>Equipment:</b> {catalog.count_days}
                  </p>
                  <p>
                    <b>Level:</b> {catalog.level}
                  </p>
                  <p>
                    <b>Video:</b> {catalog.videos_number}
                  </p>
                  <p>
                    <b>Updated:</b> {dateUpdate}
                  </p>
                  <p>
                    <b>Rating:</b> {catalog.rating}
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
