import React from "react";
import { NavLink } from "react-router-dom";

// import CheckIcon from "@material-ui/icons/Check";
// import ClearIcon from "@material-ui/icons/Clear";
// Settings
import settings from "../../services/settings";
// core components
import TemplateList from "components/TemplateList";

export default function CatalogsList({ catalogs }) {
  console.log("CatalogsList ~ catalogs ==>> ", catalogs);

  const catalogsListData = catalogs.results;
  const siteUrl = settings.SITE_NAV_LINK;
  const catalogItemUrl = `${siteUrl}/catalogues`;
  const catalogTtile = `${catalogs.count} Catalogs`;
  const tableHead = [
    "ID",
    "Photo",
    "Title",
    "User",
    "Status",
    "Rating",
    "Version",
    "Pricee",
    "Publication",
  ];
  const tableData = catalogsListData.map(
    ({
      id,
      title_photo,
      title,
      user,
      status,
      rating,
      version,
      price,
      at_publication,
    }) => [
      <NavLink to={`${catalogItemUrl}/${id}`} key={id}>
        {id}
      </NavLink>,

      <>
        <img src={title_photo.low} alt={title} /> &nbsp;
      </>,
      title,
      <>
        {user.avatar.low ? <img src={user.avatar.low} alt={user.autor} /> : ""}
        &nbsp;
        {user.autor}
      </>,
      status,
      rating,
      version,
      price,
      at_publication,
    ]
  );

  console.log("CatalogsList ~ catalogsListData ==>> ", catalogsListData);

  return (
    <TemplateList
      title={catalogTtile}
      tableHead={tableHead}
      tableData={tableData}
    />
  );
}
