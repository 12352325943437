// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Container_container__3RIox {\n  display: block;\n\n  max-width: 600px;\n  height: auto;\n\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 50px;\n  padding: 15px;\n\n  text-align: center;\n\n  background-color: #fff;\n  box-shadow: 0 0 5px rgb(0 0 0 / 10%);\n}\n", "",{"version":3,"sources":["webpack://src/components/Container/Container.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;;EAEd,gBAAgB;EAChB,YAAY;;EAEZ,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;;EAEb,kBAAkB;;EAElB,sBAAsB;EACtB,oCAAoC;AACtC","sourcesContent":[".container {\n  display: block;\n\n  max-width: 600px;\n  height: auto;\n\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 50px;\n  padding: 15px;\n\n  text-align: center;\n\n  background-color: #fff;\n  box-shadow: 0 0 5px rgb(0 0 0 / 10%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Container_container__3RIox"
};
export default ___CSS_LOADER_EXPORT___;
