import React from "react";
import { NavLink } from "react-router-dom";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
// Settings
import settings from "../../services/settings";
// core components
import TemplateList from "components/TemplateList";

export default function WorkoutsList({ workouts }) {
  console.log("WorkoutsList ~ workouts ==>> ", workouts);

  const workoutsListData = workouts.results;
  const siteUrl = settings.SITE_NAV_LINK;
  const workoutItemUrl = `${siteUrl}/workouts`;
  const workoutTtile = `${workouts.count} Workouts`;
  const tableHead = [
    "ID",
    "Name",
    "User",
    "Activity type",
    "Catalogue",
    "Exercises",
    "Comments",
    "Private",
  ];
  const tableData = workoutsListData.map(
    ({
      id,
      name,
      user,
      sport_category,
      catalogue_data,
      count_exercises,
      comments_count,
      is_private,
    }) => [
      <NavLink to={`${workoutItemUrl}/${id}`} key={id}>
        {id}
      </NavLink>,

      name,
      <>
        {user.avatar.low ? <img src={user.avatar.low} alt={user.name} /> : ""}
        &nbsp;
        {user.name}
      </>,
      sport_category ? sport_category.name : "-",
      catalogue_data ? catalogue_data.name : "-",
      count_exercises,
      comments_count,
      is_private ? <CheckIcon /> : <ClearIcon />,
    ]
  );

  console.log("WorkoutsList ~ workoutsListData ==>> ", workoutsListData);

  return (
    <TemplateList
      title={workoutTtile}
      tableHead={tableHead}
      tableData={tableData}
    />
  );
}
