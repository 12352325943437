import React from "react";
import { useSelector } from "react-redux";
// import { Form, Col, Row, Button } from "react-bootstrap";
import { authSelectors } from "../../redux/auth";
import Container from "components/Container";

import ModalError from "components/ModalError";
import AuthForm from "components/AuthForm";

export default function LoginView() {
  const loginErrors = useSelector(authSelectors.getLoginErrors);

  console.log("LoginView ~ loginErrors  ==>> ", loginErrors);

  return (
    <>
      {loginErrors && <ModalError msg={"Incorrect login or password"} />}

      <Container>
        <AuthForm />
      </Container>
    </>
  );
}
