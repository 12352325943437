import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import authSelectors from "../../redux/auth/authSelectors";
// import Container from "components/Container";

console.log("PublicRoute before ");

export default function PublicRoute({
  children,
  restricted = false,
  ...routeProps
}) {
  // console.log("PublicRoute children ==>> ", children);
  // console.log("PublicRoute ~ ...routeProps ==>> ", { ...routeProps });

  const IsLoggedIn = useSelector(authSelectors.getIsLoggedIn);
  const isRedirect = IsLoggedIn && restricted;

  return (
    <Route {...routeProps}>
      {isRedirect ? <Redirect to="/security/admin/dashboard" /> : children}
    </Route>
  );
}

// PublicRoute.defaultTypes = {
//   children: PropTypes.object,
// };

PublicRoute.propTypes = {
  children: PropTypes.element,
  restricted: PropTypes.bool,
};
