import React from "react";
import PropTypes from "prop-types";

import styles from "./Container.module.css";

const Container = ({ children }) => {
  console.log("Container type children ==> ", typeof children);
  console.log("Container  children ==> ", children);

  return <div className={styles.container}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.object,
};

export default Container;
