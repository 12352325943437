import React, { Suspense, useEffect } from "react";
import { Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authOperations, authSelectors } from "./redux/auth";

// core components
import Loader from "./components/Loader";
import LoginView from "./views/LoginView/LoginView";
import PublicRoute from "components/PublicRoute";
import PrivateRoute from "components/PrivateRoute";
import Admin from "layouts/Admin";
import TemplateItem from "components/TemplateItem";
import WorkoutItem from "components/WorkoutItem";
import CatalogItem from "components/CatalogItem";

// const AuthForm = lazy(() =>
//     import("./components/AuthForm" /* webpackChunkName: "auth-form" */)
// );

function App() {
  const dispatch = useDispatch();
  const isFetchingCurrentUser = useSelector(authSelectors.getIsFetchingCurrent);

  // console.log("App ~ isFetchingCurrentUser ==>> ", isFetchingCurrentUser);

  useEffect(() => {
    dispatch(authOperations.fetchCurrentUser());
  }, [dispatch]);

  return (
    <>
      {isFetchingCurrentUser ? (
        <Loader />
      ) : (
        <Suspense fallback={<Loader />}>
          {
            <Switch>
              <PublicRoute exact path="/login" restricted>
                <LoginView />
              </PublicRoute>

              <PrivateRoute exact path="/security/admin/catalogues/:catalogid">
                <TemplateItem child={<CatalogItem />} />
              </PrivateRoute>

              <PrivateRoute exact path="/security/admin/workouts/:workoutid">
                <TemplateItem child={<WorkoutItem />} />
              </PrivateRoute>

              <PrivateRoute path="/security/admin">
                <Admin />
              </PrivateRoute>

              <Redirect from="/" to="/login" />
            </Switch>
          }
        </Suspense>
      )}
    </>
  );
}

export default App;
