import React, { useState, useEffect } from "react";

import Loader from "components/Loader";
import CatalogsList from "components/CatalogsList";
import mmApi from "../../services/mmApi";

export default function CataloguesView() {
  const [catalogs, setCatalogs] = useState(null);

  useEffect(() => {
    console.log("useEffect ~ useEffect: ");
    fetchDataDash();
  }, []);

  const fetchDataDash = () => {
    mmApi
      .fetchCatalogs()
      .then((data) => {
        console.log("DATA fetchCatalogs ~ data: ", data);

        setCatalogs(data);
      })
      .catch((error) => {
        console.log("DATA fetchCatalogs ~ ERROR: ", error);
      })
      .finally(() => {
        console.log("DATA fetchCatalogs ~ FINALY: ");
      });
  };

  console.log("CataloguesView ~ workouts ===>> ", catalogs);

  return <>{catalogs ? <CatalogsList catalogs={catalogs} /> : <Loader />}</>;
}
