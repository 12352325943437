import axios from "axios";
import settings from "./settings";

// axios.defaults.baseURL = "http://192.168.31.204:8080/auth/mmadmin/";
// axios.defaults.baseURL = "http://192.168.31.204:8080/auth/mmadmin/";
axios.defaults.baseURL = settings.API_BASE_URL;

async function fetchDashboard() {
  const { data } = await axios.get("/dashboard/");
  console.log("fetchDashboard ~ data === >>>  ", data);

  return data;
}

async function fetchWorkouts() {
  const { data } = await axios.get("/workouts/");
  console.log("fetchWorkouts ~ data === >>>  ", data);

  return data;
}

async function fetchWorkoutItem(pk) {
  const { data } = await axios.get(`/workouts/${pk}`);
  console.log("fetchWorkouts ~ data === >>>  ", data);

  return data;
}

async function fetchCatalogs() {
  const { data } = await axios.get("/catalogs/");
  console.log("fetchCatalogs ~ data === >>>  ", data);

  return data;
}

async function fetchCatalogAbout(pk) {
  const { data } = await axios.get(`/catalogs/${pk}/about`);
  console.log("fetchCatalogAbout ~ data === >>>  ", data);

  return data;
}

async function fetchCatalogDetails(pk) {
  const { data } = await axios.get(`/catalogs/${pk}/details`);
  console.log("fetchCatalogAbout ~ data === >>>  ", data);

  return data;
}

export default {
  fetchDashboard,
  fetchWorkouts,
  fetchWorkoutItem,
  fetchCatalogs,
  fetchCatalogAbout,
  fetchCatalogDetails,
};
