const getIsLoggedIn = (state) => state.auth.isLoggedIn;
const getUsername = (state) => state.auth.user.username;
const getUserAvatar = (state) => state.auth.user.avatar.base;
const getIsFetchingCurrent = (state) => state.auth.isFetchingCurrentUser;
const getRegisterErrors = (state) => state.auth.registerError;
const getLoginErrors = (state) => state.auth.loginError;

const authSelectors = {
  getIsLoggedIn,
  getUsername,
  getUserAvatar,
  getIsFetchingCurrent,
  getRegisterErrors,
  getLoginErrors,
};

export default authSelectors;
