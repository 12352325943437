import { createSlice } from "@reduxjs/toolkit";
import authOperations from "./authOperations";

const initialState = {
  // user: { name: null, email: null, id: null, username: null, fullname: null },
  user: {
    id: null,
    username: null,
    fullname: null,
    avatar: {},
    slug: null,
  },
  token: null,
  isLoggedIn: false,
  isFetchingCurrentUser: true,
  registerError: null,
  loginError: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [authOperations.logIn.fulfilled](state, action) {
      console.log("logIn.fulfilled state ==>> ", state);
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isFetchingCurrentUser = false;
      state.isLoggedIn = true;
      state.registerError = null;
      state.loginError = null;
    },
    [authOperations.logIn.rejected](state, action) {
      console.log("logIn.rejected state ==>> ", state);
      state.isLoggedIn = false;
      state.registerError = null;
      state.isFetchingCurrentUser = false;
      state.loginError = action.error.message;
    },
    [authOperations.logOut.fulfilled](state, action) {
      console.log("logOut fulfilled action  ==>>  ", action);
      state.user = { name: null, email: null };
      state.token = null;
      state.isLoggedIn = false;
      state.registerError = null;
      state.loginError = null;
    },
    [authOperations.fetchCurrentUser.fulfilled](state, action) {
      console.log("fetchCurrentUser fulfilled payload: ==>> ", action);
      state.user = action.payload;
      state.isLoggedIn = true;
      state.isFetchingCurrentUser = false;
      state.registerError = null;
      state.loginError = null;
    },
    [authOperations.fetchCurrentUser.pending](state) {
      console.log("fetchCurrentUser.pending state ==>> ", state);
      state.isFetchingCurrentUser = true;
    },
    [authOperations.fetchCurrentUser.rejected](state, action) {
      console.log("fetchCurrentUser rejected state  ==>>  ", state);
      console.log("fetchCurrentUser rejected action  ==>>  ", action);
      state.isFetchingCurrentUser = false;
      // state.registerError = action.error.message;
    },
  },
});

export default authSlice.reducer;
