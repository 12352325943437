import { configureStore } from "@reduxjs/toolkit";
// Logger with default options
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth/authSlice";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["token"],
};

const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
  },
  // middleware,
  devTools: process.env.NODE_ENV === "development",
});

let persistor = persistStore(store);

export default { store, persistor };
