// import React from "react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { Switch, Route, Redirect } from "react-router-dom";

// import settings from "../../services/settings";
// import Loader from "components/Loader";

import mmApi from "../../services/mmApi";

const REQUEST = {
  datails: mmApi.fetchCatalogDetails,
  reviews: mmApi.fetchCatalogDetails,
  workouts: mmApi.fetchCatalogDetails,
  qa: mmApi.fetchCatalogDetails,
};

export default function TemplateItemTab({ ...rest }) {
  console.log("TemplateItem ~ rest ==>> ", { ...rest });

  const { catalogid } = useParams();
  const { name, requestNameApi } = { ...rest };
  const [catalog, setCatalog] = useState(null);
  const requestName = REQUEST[`${requestNameApi}`];

  console.log("TemplateItemTab ~ catalogid ==>> ", catalogid);

  useEffect(() => {
    console.log("TemplateItemTab useEffect ~ useEffect: ");
    fetchCatalog();
  }, []);

  const fetchCatalog = () => {
    requestName(catalogid)
      .then((data) => {
        console.log("DATA fetchCatalog ~ data: ", data);
        setCatalog(data);
      })
      .catch((error) => {
        console.log("DATA fetchCatalog ~ ERROR: ", error);
      })
      .finally(() => {
        console.log("DATA fetchCatalog ~ FINALY: ");
      });
  };

  console.log(`TemplateItemTab ~ Catalog ${requestNameApi} ==>> `, catalog);

  return (
    <div>
      <div>TemplateItemTab {name}</div>
    </div>
  );
}
