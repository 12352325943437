// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader_div_loader__2XBYZ {\n  margin-top: 25px;\n\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://src/components/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;;EAEhB,kBAAkB;AACpB","sourcesContent":[".div_loader {\n  margin-top: 25px;\n\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"div_loader": "Loader_div_loader__2XBYZ"
};
export default ___CSS_LOADER_EXPORT___;
