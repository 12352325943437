const request_host = window.location.host;
let API_BASE_URL = "http://192.168.31.204:8080/api/auth/mmadmin/";
// let API_BASE_URL = "https://develop.musclemadness.co/api/auth/mmadmin/";

let SITE_BASE_URL = "http://192.168.31.204:8080/api/auth/mmadmin/";
let SITE_NAV_LINK = "/security/admin";

if (request_host === "dev-admin.musclemadness.co") {
  API_BASE_URL = "https://develop.musclemadness.co/api/auth/mmadmin/";
  SITE_BASE_URL = "https://develop.musclemadness.co";
  // SITE_NAV_LINK = "https://dev-admin.musclemadness.co/security/admin";
} else if (request_host === "musclemadness.co") {
  API_BASE_URL = "https://musclemadness.co/api/auth/mmadmin/";
  SITE_BASE_URL = "https://musclemadness.co";
  // SITE_NAV_LINK = "https://dev-admin.musclemadness.co/security/admin";
}

console.log("window.location.host ==>> ", window.location.host);
console.log("API_BASE_URL ==>> ", API_BASE_URL);

export default { API_BASE_URL, SITE_BASE_URL, SITE_NAV_LINK };
