import React, { useState, useEffect } from "react";

import Loader from "components/Loader";
import WorkoutsList from "components/WorkoutsList";
import mmApi from "../../services/mmApi";

export default function WorkoutsView() {
  const [workouts, setWorkouts] = useState(null);

  useEffect(() => {
    console.log("useEffect ~ useEffect: ");
    fetchDataDash();
  }, []);

  const fetchDataDash = () => {
    mmApi
      .fetchWorkouts()
      .then((data) => {
        console.log("DATA fetchWorkouts ~ data: ", data);

        setWorkouts(data);
      })
      .catch((error) => {
        console.log("DATA fetchWorkouts ~ ERROR: ", error);
      })
      .finally(() => {
        console.log("DATA fetchWorkouts ~ FINALY: ");
      });
  };

  console.log("WorkoutsView ~ workouts ===>> ", workouts);

  return <>{workouts ? <WorkoutsList workouts={workouts} /> : <Loader />}</>;
}
