import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import settings from "../../services/settings";

// axios.defaults.baseURL = "http://192.168.31.204:8080/auth/mmadmin/";
// axios.defaults.baseURL = "https://develop.musclemadness.co/auth/mmadmin/";
axios.defaults.baseURL = settings.API_BASE_URL;

const token = {
  set(token) {
    axios.defaults.headers.common.Authorization = `JWT ${token}`;
  },
  unset() {
    axios.defaults.headers.common.Authorization = "";
  },
};

/*
 * POST @ /users/login
 * body: { email, password }
 * После успешного логина добавляем токен в HTTP-заголовок
 */
const logIn = createAsyncThunk("auth/login", async (credentials, thunkAPI) => {
  console.log("logIn ~ credentials ==>> ", credentials);

  const newCcredentials = {
    username: credentials.email,
    password: credentials.password,
  };

  console.log("credentials", credentials);
  console.log("newCcredentials", newCcredentials);

  //   const state = thunkAPI.getState();

  try {
    const { data } = await axios.post("token/", newCcredentials);
    console.log("logIn ~ data ==>  ", data);
    console.log("logIn ~ data.access ==>  ", data.token);
    token.set(data.token);
    return data;
  } catch (error) {
    // TODO: Добавить обработку ошибки error.message
    console.log("login ~ error message  ==>> ", error.message);
    token.unset();
    return thunkAPI.rejectWithValue(error.message);
  }
});

/*
 * POST @ /users/logout
 * headers: Authorization: Bearer token
 * После успешного логаута, удаляем токен из HTTP-заголовка
 */
const logOut = createAsyncThunk("auth/logout", async () => {
  try {
    await axios.post("/users/logout");
    token.unset();
  } catch (error) {
    // TODO: Добавить обработку ошибки error.message
  }
});

/*
 * GET @ /users/current
 * headers:
 *    Authorization: Bearer token
 *
 * 1. Забираем токен из стейта через getState()
 * 2. Если токена нет, выходим не выполняя никаких операций
 * 3. Если токен есть, добавляет его в HTTP-заголовок и выполянем операцию
 */
const fetchCurrentUser = createAsyncThunk(
  "auth/refresh",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const persistedToken = state.auth.token;
    console.log("fetchCurrentUser state ==>> ", state);
    console.log("persistedToken ==>> ", persistedToken);

    if (persistedToken === null) {
      console.log("Токена нет, уходим из fetchCurrentUser");
      return thunkAPI.rejectWithValue();
    }

    token.set(persistedToken);

    try {
      const { data } = await axios.get("current-user/");
      console.log("fetchCurrentUser data ==>> ", data);
      return data;
    } catch (error) {
      // TODO: Добавить обработку ошибки error.message
      console.log("fetchCurrentUser error  ==>> ", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const operations = {
  logOut,
  logIn,
  fetchCurrentUser,
};
export default operations;
