import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

const ModalError = ({ msg }) => {
  const [show, setShow] = useState(true);
  console.log("ModalError ~ show ==>>  ", show);
  console.log("ModalError ~ msg ==>>  ", msg);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <SnackbarContent message={msg} close color="danger" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ModalError.propTypes = {
  msg: PropTypes.string,
};

export default ModalError;
